body {
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    touch-action: manipulation;
}

body, input, textarea, select {
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: #F4F4F4;
}
